import { RichUtils } from "draft-js";

export function toggleBlockStyle(context, style) {
    context.onChange(RichUtils.toggleInlineStyle(context.editorState, style))
}

const generateFontSizes = () => {
    let fontSizes = {};
    for (let i = 9; i <= 59; i++) {
        fontSizes[`FONT_SIZE_${i}`] = {fontSize: (i / 10 + 0.1).toFixed(1) + 'rem'};
    }
    return fontSizes
};
export const CUSTOM_STYLE_MAP = {
    ...generateFontSizes(),
    ...{
        'THIN': {fontWeight: 'lighter'},
        'BOLD': {fontWeight: 'bold'},
        'UNDERLINE': {textDecoration: 'underline'},
        'CODE': {fontFamily: 'monospace'}
    }
}
