import React from 'react';
import { ControlBlock, ControlButton } from '../_constructor';
import { EditorState, Modifier } from 'draft-js';

export const TEXT_ALIGNMENT_BUTTONS = [
    {label: 'align-left', style: 'align-left'},
    {label: 'align-center', style: 'align-center'},
    {label: 'align-right', style: 'align-right'},
    {label: 'align-justify', style: 'align-justify'},
];

export default class TextAlignment extends React.Component {

    setAlignment = data => {
        // alignment is not supported in Draft.js so the workaround is to set a custom object to block's data
        let { editorState, onChange } = this.props;
        // this custom object allows to get info about alignment later in renderer (see passBlockClasses)
        const newContentState = Modifier.setBlockData(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            {align: data}
        );

        let finalState = EditorState.push(editorState, newContentState, 'change-block-data');
        onChange(finalState)
    }

    renderTextStyle = () => {

        let {editorState} = this.props;

        return TEXT_ALIGNMENT_BUTTONS.map((button, index) => {

            let currentState = editorState;
            let selection = currentState.getSelection();
            let align = currentState
                .getCurrentContent()
                .getBlockForKey(selection.getStartKey())
                .getData().get('align');
            let active = button.label === align;

            if (!align && button.label === 'align-left') active = true;

            return (
                <ControlButton
                    active={active}
                    icon={'fas fa-' + button.label}
                    onClick={() => this.setAlignment(button.style)}
                    key={index}
                />
            )
        })
    }


    render() {
        return (
            <ControlBlock>
                {this.renderTextStyle()}
            </ControlBlock>
        );
    }
}