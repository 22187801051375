import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FoldingContent from "./FoldingContent";
import { DEBUG } from "../../config";
import { dispatchNotification } from "../../layouts/app/components/notificationSystem/notifications";

function FoldingBlock({style, className, title, children, id, onFoldSwitch, ...props}) {

    const storedStates = typeof window !== 'undefined' ? localStorage.getItem('blocks_fold') : '';

    const storedIndex = storedStates ? JSON.parse(storedStates).findIndex(block => block.id === id) : -1;
    const storedFolded = storedStates ? JSON.parse(storedStates)[storedIndex]?.folded : !!props.folded;

    const [folded, setFolded] = useState(false);

    useEffect(() => {
        setFolded(typeof storedFolded === 'boolean' ? storedFolded : !!props.folded)
    }, []);

    const handleCollapse = () => {
        setFolded(!folded);
        if (onFoldSwitch) onFoldSwitch(!folded)
        // save state only for blocks with id provided
        if (!id) return;
        const stored = localStorage.getItem('blocks_fold');
        let newStorage = [];
        if (stored) {

            const blocks = JSON.parse(stored);
            const foundIndex = JSON.parse(stored).findIndex(block => block.id === id);

            if (foundIndex !== -1) {
                newStorage = [
                    ...blocks.slice(0, foundIndex),
                    ...blocks.slice(foundIndex + 1),
                    ...[{
                        ...JSON.parse(stored)[foundIndex],
                        ...{folded: !folded}
                    }]
                ]
            } else {
                newStorage = [...JSON.parse(stored), ...[{id, folded: !folded}]]
            }
        } else {
            newStorage = [{id, folded: !folded}];
            if (!!DEBUG) dispatchNotification({
                message: 'I, the code, will try to remember your choice!',
                type: 'success',
            })
        }
        localStorage.setItem('blocks_fold', JSON.stringify(newStorage))
    };

    const foldedClass = folded ? ' foldingBlock--folded' : '';

    return (
        <div
            style={style}
            className={`foldingBlock${foldedClass}${className ? ' ' + className : ''}`}
            data-folded={!!folded}
            id={id}
        >
            <div className='foldingBlock__head' onClick={() => handleCollapse()}>
                <p className='foldingBlock__head__title'>{title}</p>
                <i className='fas fa-chevron-down foldingBlock__head__icon'/>
            </div>
            <FoldingContent folded={folded}>
                {children}
            </FoldingContent>
        </div>
    )
}

export default FoldingBlock

FoldingBlock.propTypes = {
    folded: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    id: PropTypes.string
}