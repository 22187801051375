import { EditorState, RichUtils } from "draft-js";
import { dispatchNotification } from "../../../../layouts/app/components/notificationSystem/notifications";

const onSelectAbsence = () => {
    dispatchNotification({
        message: 'Please, select text first',
        type: 'warning'
    });
}

export function getDecoratorEntity(context) {

    const selection = context.editorState.getSelection();

    if (selection.isCollapsed()) {
        onSelectAbsence();
        return {};
    }

    const contentState = context.editorState.getCurrentContent();
    const startKey = context.editorState.getSelection().getStartKey();
    // get plain selected text
    const currentContentBlock = contentState.getBlockForKey(startKey);
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    // no text is selected or selected is not a text
    if (!selectedText) {
        onSelectAbsence();
        return {}
    }
    // define if selected is an entity and has some data
    const startOffset = context.editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    const entity = entityKey ? contentState.getEntity(entityKey) : null;
    // get all data stored in selection
    // const type = entity ? entity.getType() : undefined;
    const data = entity ? entity.getData() : {};

    return {data, selectedText};
}

export function insertDecorator(data, type, context) {

    const contentState = context.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(type, 'MUTABLE', data);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(context.editorState, {currentContent: contentStateWithEntity});

    context.setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));

    context.focus()
}