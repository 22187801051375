import React, { useContext } from 'react';
import { ControlBlock, ControlButton } from '../_constructor';
import { DraftContext } from "../../../DraftEditor";
import { toggleBlockStyle } from "../../../helpers/styles";

const TEXT_STYLING_BUTTONS = [
    {label: 'b', style: 'BOLD'},
    {label: 't', style: 'THIN'},
    {label: 'i', style: 'ITALIC'},
    {label: 'u', style: 'UNDERLINE'},
    {label: 'm', style: 'CODE'},
];

export default function FontStyling() {

    const context = useContext(DraftContext);

    const onStyleChange = style => {

        let newStyle = style;

        if (style === 'THIN' || style === 'BOLD') {

            const opposite = style === 'THIN' ? 'BOLD' : 'THIN';
            const currentStyle = context.editorState.getCurrentInlineStyle();
            const isBattle = currentStyle.has(opposite);

            if (isBattle) newStyle = opposite;
        }

        toggleBlockStyle(context, newStyle);
    }

    return (
        <ControlBlock>
            {
                TEXT_STYLING_BUTTONS.map((button, index) => {

                    const currentStyle = context.editorState.getCurrentInlineStyle();
                    const active = currentStyle.has(button.style);

                    return (
                        <ControlButton
                            className={`draft-ctrl-button__${button.style.toLowerCase()}`}
                            active={active}
                            text={button.label}
                            onClick={() => onStyleChange(button.style)}
                            key={index}
                        />
                    )
                })
            }
        </ControlBlock>
    );
}