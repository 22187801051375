import React from 'react';
import { ControlBlock, ControlButton } from '../_constructor';
import { RichUtils } from 'draft-js';

export default function FontMain({context, stylesMap, defaultFontSize}) {


    const {editorState, onChange} = context;

    const onFontSizeClick = (increment) => {

        let inlineStyle = editorState.getCurrentInlineStyle();
        let fontsStylesKeys = Object.keys(stylesMap);
        fontsStylesKeys = fontsStylesKeys.filter(key => key.includes('FONT_SIZE_'));
        let existingFontSize = fontsStylesKeys.find(style => inlineStyle.has(style) && style.includes('FONT_SIZE_'))
        let newFontSize = null;
        let modifiedState = editorState;

        if (!!existingFontSize) {
            // define new required font size ( ['FONT_SIZE_', size] )
            let currentFontSize = parseFloat(existingFontSize.split('FONT_SIZE_')[1]);
            newFontSize = 'FONT_SIZE_' + (currentFontSize + (increment ? +1 : -1));
            // prevent updating if style does not exist
            if (!fontsStylesKeys.some(style => style === newFontSize)) return;
            // switch off previous font size to exclude it from temporary contentState
            modifiedState = RichUtils.toggleInlineStyle(modifiedState, existingFontSize);
            // push new size to temporary state with removed previous style value
            modifiedState = RichUtils.toggleInlineStyle(modifiedState, newFontSize);
        } else {
            let defaultKeyIndex = 6;
            if (!!defaultFontSize) {
                defaultKeyIndex = fontsStylesKeys.findIndex(key => stylesMap[key]['fontSize'] === defaultFontSize)
            }
            newFontSize = fontsStylesKeys[defaultKeyIndex]; // default by styles
            modifiedState = RichUtils.toggleInlineStyle(modifiedState, newFontSize);
        }
        // push final version of temporary state
        onChange(modifiedState);
    }

    const renderFontSize = () => {

        let inlineStyle = editorState.getCurrentInlineStyle();
        let fontsStylesKeys = Object.keys(stylesMap);
        fontsStylesKeys = fontsStylesKeys.filter(key => key.includes('FONT_SIZE_'));
        let existingFontSize = fontsStylesKeys.find(style => inlineStyle.has(style) && style.includes('FONT_SIZE_'))
        let fontSize = null;

        if (!!existingFontSize) {
            let currentFontSize = 'FONT_SIZE_' + existingFontSize.split('FONT_SIZE_')[1];
            fontSize = stylesMap[currentFontSize]['fontSize'];
        } else {
            let defaultKeyIndex = 6;
            if (!!defaultFontSize) {
                defaultKeyIndex = fontsStylesKeys.findIndex(key => stylesMap[key]['fontSize'] === defaultFontSize)
            }
            fontSize = stylesMap[fontsStylesKeys[defaultKeyIndex]]['fontSize'];
        }

        return (
            <div
                className='draft-ctrl-font-size'
                title='Actually it is rem, not pixels, but for 100% screen scale it behaves the same.'
            >
                {fontSize.split('rem')[0] * 10 + 'px'}
            </div>
        )
    }


    return (
        <React.Fragment>
            <ControlButton icon='fas fa-chevron-up' onClick={() => onFontSizeClick(true)}/>
            <ControlButton icon='fas fa-chevron-down' onClick={() => onFontSizeClick(false)}/>
            {renderFontSize()}
        </React.Fragment>
    );
}