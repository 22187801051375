import React, { useContext } from 'react';
import FontMain from "./components/FontMain";
import FontColors from './components/FontColors';
import FontStyling from './components/FontStyling';
import TextAlignment from './components/TextAlignment';
import Media from './components/Media';
import { ControlBlock, ControlButton } from './_constructor';
import { DraftContext } from "../../DraftEditor";
import { getBlockType, HEADINGS_BUTTONS, toggleBlockType, TYPES_BUTTONS } from "../../helpers/blockTypes";

export default function ControlPanel({
                                         controls,
                                         stylesMap, defaultFontSize,
                                         setShowPreview, showPreview
                                     }) {

    const context = useContext(DraftContext);

    if (!controls?.length && !context.onSubmit) return null;
    const isAllowed = control => (
        controls !== false && controls !== null && (!controls?.length || controls.includes(control))
    );

    return (
        <div className='draft-control-panel'>
            <div className='draft-control-panel__container'>
                {
                    isAllowed('fontSize') &&
                    <ControlBlock>
                        <FontMain
                            context={context}
                            stylesMap={stylesMap}
                            defaultFontSize={defaultFontSize}
                        />
                    </ControlBlock>
                }
                {
                    (isAllowed('colorFont') || isAllowed('colorBackgroundFont')) &&
                    <ControlBlock>
                        <FontColors
                            context={context}
                            stylesMap={stylesMap}
                            isAllowed={isAllowed}
                        />
                    </ControlBlock>
                }
                {isAllowed('styling') && <FontStyling/>}
                {isAllowed('alignment') &&
                    <TextAlignment onChange={context.onChange} editorState={context.editorState}/>}
                {
                    isAllowed('headings') &&
                    <ControlBlock>
                        {
                            HEADINGS_BUTTONS.map((button, index) => (
                                    <ControlButton
                                        active={button.style === getBlockType(context)}
                                        text={button.label}
                                        onClick={() => toggleBlockType(context, button.style)}
                                        key={index}
                                    />
                                )
                            )
                        }
                    </ControlBlock>
                }
                {
                    isAllowed('blockTypes') &&
                    <ControlBlock>
                        {
                            TYPES_BUTTONS.map((button, index) => (
                                    <ControlButton
                                        active={button.style === getBlockType(context)}
                                        icon={!!button.icon && button.icon}
                                        text={!button.icon && button.label}
                                        onClick={() => toggleBlockType(context, button.style)}
                                        key={index}
                                    />
                                )
                            )
                        }
                    </ControlBlock>
                }
                {
                    (
                        isAllowed('link')
                        || isAllowed('image')
                        || isAllowed('youtube')
                        || isAllowed('button')
                        || isAllowed('code')
                    )
                    && <Media isAllowed={isAllowed}/>
                }
                <ControlBlock>
                    {
                        isAllowed('preview') &&
                        <ControlButton
                            active={!!showPreview}
                            icon='fas fa-eye'
                            onClick={() => setShowPreview(!showPreview)}
                            iconColor='var(--colorAccentBasic)'
                        />
                    }
                    {
                        !!context.onSubmit &&
                        <ControlButton
                            icon='fas fa-save'
                            onClick={context.onSubmit}
                            iconColor='var(--colorAccentBasic)'
                        />
                    }
                </ControlBlock>
            </div>
        </div>
    );
}