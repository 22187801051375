import React, { useContext } from 'react';
import { ControlBlock, ControlButton } from '../_constructor';
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueImageSource from "../../blocks/image/DialogueImageSource";
import DialogueVideoYoutube from "../../blocks/videoYoutube/DialogueVideoYoutube";
import { DraftContext } from "../../../DraftEditor";
import DialogueLink from "../../decorators/link/DialogueLink";
import { getDecoratorEntity } from "../../decorators/_insert";
import DialogueButton from "../../blocks/button/DialogueButton";
import DialogueCode from "../../blocks/code/DialogueCode";

export default function Media({isAllowed}) {

    const context = useContext(DraftContext);

    return (
        <ControlBlock>
            {
                isAllowed('link') &&
                <ControlButton
                    icon='fas fa-link'
                    onClick={() => {
                        const {data, selectedText} = getDecoratorEntity(context);
                        if (!selectedText) return;
                        showModalDefault({
                            component: <DialogueLink
                                context={context}
                                selectedText={selectedText}
                                data={data}
                            />,
                            title: 'Edit link'
                        })
                    }}
                />
            }
            {
                isAllowed('image') &&
                <ControlButton
                    icon='fas fa-image'
                    onClick={() => {
                        showModalDefault({
                            component: <DialogueImageSource context={context}/>,
                            title: 'Select picture source',
                            maxWidth: '700px',
                        })
                    }}
                />
            }
            {
                isAllowed('youtube') &&
                <ControlButton
                    icon='fas fa-video'
                    onClick={() => {
                        showModalDefault({
                            component: <DialogueVideoYoutube context={context}/>,
                            title: 'Add YouTube video',
                            maxWidth: '700px',
                        })
                    }}
                />
            }
            {
                isAllowed('button') &&
                <ControlButton
                    icon='fas fa-hand-pointer'
                    onClick={() => {
                        showModalDefault({
                            component: <DialogueButton context={context}/>,
                            title: 'Add button',
                            maxWidth: '1000px',
                        })
                    }}
                />
            }
            {
                isAllowed('code') &&
                <ControlButton
                    icon='fas fa-code'
                    onClick={() => {
                        showModalDefault({
                            component: <DialogueCode context={context}/>,
                            title: 'Add code block',
                            maxWidth: '1900px',
                        })
                    }}
                />
            }
        </ControlBlock>
    );
}