import Draft, { getDefaultKeyBinding, KeyBindingUtil, RichUtils } from "draft-js";
import CodeUtils from "draft-js-code";

const {hasCommandModifier} = KeyBindingUtil;

export const handleKeyCommand = (command, editorState, context) => {
    // save content on ctrl + s and continue handling
    if (command === 'content-editor-save') context.onSubmit(editorState);
    // other cases
    let newState;

    if (CodeUtils.hasSelectionInBlock(editorState)) newState = CodeUtils.handleKeyCommand(editorState, command);
    // inserts tab when pressing "tab" key
    if (command === 'handle-tab') newState = CodeUtils.onTab(event, editorState);

    if (!newState) newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
        context.onChange(newState);
        return 'handled';
    }

    return 'not-handled';
};

export const keyBindingFn = (e, context) => {
    switch (true) {
        case e.keyCode === 83 && hasCommandModifier(e):
            return 'content-editor-save';
        case e.keyCode === 9:
            return 'handle-tab'
        case !!CodeUtils.hasSelectionInBlock(context.editorState):
            return CodeUtils.getKeyBinding(e) || Draft.getDefaultKeyBinding(e);
        default:
            return getDefaultKeyBinding(e);
    }
}