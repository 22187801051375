// deprecated (prismjs) support
export const DRAFT_CODE_LANGUAGES_DEPRECATED = {
    svg: 'xml',
    bash: 'shellscript',
    git: 'shellscript',
};

// Check languages support here: https://shiki.matsu.io/languages
export const DRAFT_CODE_LANGUAGES = [
    {code: 'apache', name: 'Apache', icon: 'fas fa-server'},
    {code: 'docker', name: 'Docker', icon: 'fab fa-docker'},
    {code: 'hcl', name: 'HCL', icon: 'fas fa-wrench'},
    {code: 'html', name: 'HTML', icon: 'fab fa-html5'},
    {code: 'http', name: 'HTTP', icon: ''},
    {code: 'ini', name: 'INI', icon: 'fas fa-wrench'},
    {code: 'javascript', name: 'JavaScript', icon: 'fab fa-js-square'},
    {code: 'jinja', name: 'Jinja', icon: 'fas fa-th-list'},
    {code: 'json', name: 'JSON', icon: 'fas fa-wrench'},
    {code: 'log', name: 'Log', icon: ''},
    {code: 'make', name: 'Make', icon: 'fas fa-terminal'},
    {code: 'markdown', name: 'Markdown', icon: 'fab fa-markdown'},
    {code: 'nginx', name: 'Nginx', icon: 'fas fa-server'},
    {code: 'php', name: 'PHP', icon: 'fab fa-php'},
    {code: 'python', name: 'Python', icon: 'fab fa-python'},
    {code: 'rust', name: 'Rust', icon: ''},
    {code: 'shellscript', name: 'Shell', icon: 'fas fa-terminal'},
    {code: 'toml', name: 'TOML', icon: 'fas fa-wrench'},
    {code: 'typescript', name: 'TypeScript', icon: 'fab fa-js-square'},
    {code: 'xml', name: 'XML', icon: 'fas fa-wrench'},
    {code: 'yaml', name: 'YAML', icon: 'fas fa-wrench'},
];

export const DRAFT_CODE_THEME_LIGHT = 'one-light';
export const DRAFT_CODE_THEME_DARK = 'material-theme-darker';

// PLEASE DO NOT CHANGE COLORS UNLESS YOU DOUBLE-CHECKED CONTRAST RATIO
export const DRAFT_FONT_COLORS = [
    // [ 'light', 'dark' ]
    ['#950000', '#ef5350'],
    ['#d32f2f', '#e57373'],
    ['#ba5100', '#ff9800'],
    ['#926800', '#fbc02d'],
    ['#166000', '#66bb6a'],
    ['#007b88', '#00bcd4'],
    ['#0275b3', '#64b5f6'],
    ['#11529c', '#2196f3'],
    ['#5e35b1', '#9877d1'],
    ['#85229f', '#ba68c8'],
    ['#FFFFFF', '#181818'],
    ['#E2E2E2', '#202020'],
    ['#C6C6C6', '#3B3B3B'],
    ['#AAAAAA', '#575757'],
    ['#8D8D8D', '#737373'],
    ['#717171', '#8F8F8F'],
    ['#555555', '#ABABAB'],
    ['#383838', '#C7C7C7'],
    ['#1C1C1C', '#E3E3E3'],
    ['#000000', '#FFFFFF']
];

export const DRAFT_FONT_BACKGROUND_COLORS = [
    // [ 'light', 'dark' ]
    ['#950000', '#ef5350'],
    ['#d32f2f', '#e57373'],
    ['#ba5100', '#ff9800'],
    ['#926800', '#fbc02d'],
    ['#166000', '#66bb6a'],
    ['#feafaf', '#421716'],
    ['#ffcdd2', '#492525'],
    ['#ffccbc', '#4b2e02'],
    ['#fff59d', '#40310c'],
    ['#b2dfdb', '#1b3d1d'],
    ['#007b88', '#00bcd4'],
    ['#0275b3', '#64b5f6'],
    ['#11529c', '#2196f3'],
    ['#5e35b1', '#9877d1'],
    ['#85229f', '#ba68c8'],
    ['#b2ebf2', '#004851'],
    ['#b3e5fc', '#223e54'],
    ['#bbdefb', '#0c314f'],
    ['#d1c4e9', '#352a48'],
    ['#e1bee7', '#34113e'],
    ['#FFFFFF', '#181818'],
    ['#E2E2E2', '#202020'],
    ['#C6C6C6', '#3B3B3B'],
    ['#AAAAAA', '#575757'],
    ['#8D8D8D', '#737373'],
    ['#717171', '#8F8F8F'],
    ['#555555', '#ABABAB'],
    ['#383838', '#C7C7C7'],
    ['#1C1C1C', '#E3E3E3'],
    ['#000000', '#FFFFFF']
];