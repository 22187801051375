import { AtomicBlockUtils, EditorState } from "draft-js";

export function insertBlock(data, type, context, entityKey) {

    if (!type) {
        console.error('No "type" property passed to add a custom block');
        return
    }

    const contentState = context.editorState.getCurrentContent();

    if (!!entityKey) { // already existing entityKey for modifying existing block
        // edit existing block instead of adding a new one
        const contentStateUpdated = contentState.mergeEntityData(entityKey, data);
        const newEditorState = EditorState.push(
            context.editorState,
            contentStateUpdated,
            'change-block-data'
        );

        context.onChange(newEditorState);

    } else {
        // add new block with data
        const contentStateWithEntity = contentState.createEntity(type, "IMMUTABLE", {...data});
        const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(context.editorState, {currentContent: contentStateWithEntity});

        context.setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, newEntityKey, " "));
    }

    context.focus()
}