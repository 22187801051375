import { DRAFT_FONT_BACKGROUND_COLORS, DRAFT_FONT_COLORS } from "../../../settings/draft";

export function setFontColors(passedColors) {
    const fontColorSource = passedColors ? passedColors : DRAFT_FONT_COLORS;
    let fontColorsMap = {};
    fontColorSource.map((color, index) => {
        fontColorsMap[`FONT_COLOR_${index + 1}`] = {'color': `var(--font-color-${index + 1})`}
    });
    return fontColorsMap
}

export function setFontBackgroundColors(passedColors) {
    const fontBackgroundColorSource = passedColors ? passedColors : DRAFT_FONT_BACKGROUND_COLORS;
    let fontBackgroundColorsMap = {};
    fontBackgroundColorSource.map((color, index) => {
        fontBackgroundColorsMap[`FONT_BACKGROUND_COLOR_${index + 1}`] = {
            'backgroundColor': `var(--font-background-color-${index + 1})`
        }
    });
    return fontBackgroundColorsMap
}

export function createFontColorVariables(dark) {
    let fontColorsVars = {};
    DRAFT_FONT_COLORS.map((color, index) => {
        fontColorsVars[`--font-color-${index + 1}`] = dark ? color[1] : color[0];
    });
    return fontColorsVars
}

export function createFontBackgroundColorVariables(dark) {
    let fontBackgroundColorsVars = {};
    DRAFT_FONT_BACKGROUND_COLORS.map((color, index) => {
        fontBackgroundColorsVars[`--font-background-color-${index + 1}`] = dark ? color[1] : color[0];
    });
    return fontBackgroundColorsVars
}