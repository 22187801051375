import React from "react";
import BlockImage from "./image/BlockImage";
import BlockVideoYoutube from "./videoYoutube/BlockVideoYoutube";
import BlockButton from "./button/BlockButton";
import BlockCode from "./code/BlockCode";

export default function defineBlockRender({block, context}) {
    const entityKey = block.getEntityAt(0);
    // check if entity is draft valid block
    if (!entityKey) return;
    // get full block data
    const entity = context.editorState.getCurrentContent().getEntity(entityKey);
    // switch if new block types will appear in future
    switch (entity.getType()) {
        case 'img':
            return {
                component: () => (
                    <BlockImage
                        context={context}
                        imageData={entity.getData()}
                        entityKey={entityKey}
                    />
                ),
            };
        case 'video':
            return {
                component: () => (
                    <BlockVideoYoutube
                        context={context}
                        videoData={entity.getData()}
                        entityKey={entityKey}
                    />
                ),
            };
        case 'button':
            return {
                component: () => (
                    <BlockButton
                        context={context}
                        data={entity.getData()}
                        entityKey={entityKey}
                    />
                ),
            };
        case 'code':
            return {
                component: () => (
                    <BlockCode
                        context={context}
                        data={entity.getData()}
                        entityKey={entityKey}
                    />
                ),
            };
        default:
            return
    }
}