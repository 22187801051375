import React from "react";
import Messages from "../Messages";

export function CardItem({
                             style,
                             onClick,
                             notContained,
                             padding,
                             children,
                             className,
                             id, // identifies message block
                             messages,
                             error,
                             warning,
                             success,
                             info,
                             tabIndex,
                         }) {

    let point = '';

    const _info = messages?.some(e => e.block === id && e.type === 'info');
    const _success = messages?.some(e => e.block === id && e.type === 'success');
    const _warning = messages?.some(e => e.block === id && e.type === 'warning');
    const _error = messages?.some(e => e.block === id && e.type === 'error');

    if (_info || info) point = ' card-item--info';
    if (_success || success) point = ' card-item--success';
    if (_warning || warning) point = ' card-item--warning';
    if (_error || error) point = ' card-item--error';

    return (
        <div
            className={`${!!notContained ? 'card-item-not-contained' : 'card-item'}${point}${className ? ' ' + className : ''}`}
            style={{
                ...(style ? style : {}),
                ...(padding ? {padding} : {}),
            }}
            id={id}
            tabIndex={tabIndex}
            onClick={onClick ? onClick : undefined}
        >
            {children}
        </div>
    );
}

export default function Card({
                                 style,
                                 title,
                                 children,
                                 className,
                                 id, // identifies message container
                                 messages,
                                 showMessages,
                                 error,
                                 warning,
                                 success,
                                 info,
                                 darker,
                             }) {

    let point = '';

    const _info = messages?.some(e => e.container === id && e.type === 'info');
    const _success = messages?.some(e => e.container === id && e.type === 'success');
    const _warning = messages?.some(e => e.container === id && e.type === 'warning');
    const _error = messages?.some(e => e.container === id && e.type === 'error');

    if (_info || info) point = ' card--info';
    if (_success || success) point = ' card--success';
    if (_warning || warning) point = ' card--warning';
    if (_error || error) point = ' card--error';

    return (
        <div
            className={`${darker ? 'card--darker' : 'card'}${point}${className ? ' ' + className : ''}`}
            style={style}
            id={id}
        >
            {!!title && <div className='card-title'>{title}</div>}
            {showMessages && !!point &&
                <div className='card-messages'><Messages messages={messages} container={id}/></div>}
            <div className='card-container'>
                <div className='card-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}