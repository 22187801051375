import { RichUtils } from "draft-js";

export function getBlockType(context) {
    return context.editorState
                  .getCurrentContent()
                  .getBlockForKey(context.editorState.getSelection().getStartKey())
                  .getType();
}

export function toggleBlockType(context, type) {
    context.onChange(RichUtils.toggleBlockType(context.editorState, type))
}


export const HEADINGS_BUTTONS = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
];

export const TYPES_BUTTONS = [
    {label: 'P', style: 'paragraph'},
    {label: '""', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: '{}', style: 'code-block', icon: 'fas fa-code'},
];

export const DEFAULT_CLASSES = {
    'header-one': 'content-editor__header-one',
    'header-two': 'content-editor__header-two',
    'header-three': 'content-editor__header-three',
    'header-four': 'content-editor__header-four',
    'header-five': 'content-editor__header-five',
    'header-six': 'content-editor__header-six',
    'unstyled': 'content-editor__unstyled',
    'paragraph': 'content-editor__paragraph',
    'blockquote': 'content-editor__blockquote',
    'code-block': 'content-editor__code-block',
    'unordered-list-item': 'content-editor__unordered-list-item',
    'ordered-list-item': 'content-editor__ordered-list-item',
}

export const DEFAULT_STYLES = {
    'align-left': 'content-editor__default-styles__align--left',
    'align-right': 'content-editor__default-styles__align--right',
    'align-center': 'content-editor__default-styles__align--center',
    'align-justify': 'content-editor__default-styles__align--justify',
}

export function blockStyleFn(contentBlock, styles) {

    const type = contentBlock.getType();
    const align = contentBlock.getData().get('align');

    let className = '';
    // set a classname to a block defined by TYPES_BUTTONS and HEADINGS_BUTTONS
    if (Object.keys(DEFAULT_CLASSES).includes(type)) {
        className = DEFAULT_CLASSES[type]; // if no custom classNames passed, use defaults
    }
    // add custom block classnames on top of default
    if (!!styles && Object.keys(styles).includes(type)) {
        className += ' ' + styles[type];
    }
    // If some data is provided in block's "style" custom object, search for it and apply.
    // This allows to apply a custom style to the whole block with type style instead of overwriting it.
    // If you need multiple custom styles, feel free to change code for mapping purposes
    if (!!DEFAULT_STYLES[align]) className += ' ' + DEFAULT_STYLES[align];

    return className
}