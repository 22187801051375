import { CompositeDecorator } from "draft-js";
import { BlockLink, findLinkEntities } from "./link/BlockLink";

export default function defineDecoratorRender() {
    return new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: BlockLink
        },
        {
            strategy: (contentBlock, callback, contentState) => {
                // console.log(contentBlock)
                // console.log(contentState)
                // console.log(contentBlock)
            },
            component: props => <div>{props.children}</div>
        }
    ]);
}