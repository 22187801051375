import React, { useState } from "react";
import Input from "../../../../Input";
import Switch from "../../../../Switch";
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import Button from "../../../../ripple/Button";
import { CardItem } from "../../../../card/Card";
import { insertBlock } from "../_insert";
import { URL_PICTURES_FULL } from "../../../../../config";

export default function DialogueImage({imageData, entityKey, context}) {

    const [align, setAlign] = useState(imageData.align || 'center');
    const [caption, setCaption] = useState(imageData.caption || '');
    const [showCaption, setShowCaption] = useState(!!imageData.showCaption);
    const [limitHeight, setLimitHeight] = useState('limitHeight' in imageData ? imageData.limitHeight : true);
    const [lightBorder, setLightBorder] = useState('lightBorder' in imageData ? imageData.lightBorder : true);
    const [heavyBorder, setHeavyBorder] = useState('heavyBorder' in imageData ? imageData.heavyBorder : false);
    const [isLink, setIsLink] = useState('isLink' in imageData ? imageData.isLink : false);
    const [nofollow, setNofollow] = useState('nofollow' in imageData ? imageData.nofollow : true);
    const [attachedLink, setAttachedLink] = useState('attachedLink' in imageData ? imageData.attachedLink : imageData.attachedLink);

    const renderAlignOptions = () => {

        let options = [
            {position: 'flex-start', icon: 'left'},
            {position: 'center', icon: 'justify'},
            {position: 'flex-end', icon: 'right'},
        ];

        return options.map((option, index) => {
            return (
                <div
                    className='draft-image-options__content__container__align__container'
                    onClick={() => setAlign(option.position ? option.position : 'center')}
                    key={index}
                >
                    <i className={`fas fa-align-${option.icon}`}/>
                </div>
            )
        })
    };

    const renderLeft = () => {
        return (
            <div className='draft-image-options__content__left-side'>
                <div
                    className='draft-image-options__content__container'
                    style={{
                        '--align': align,
                        '--max-image-height': limitHeight ? '70rem' : '100%',
                        '--image-border': lightBorder ? '0.1rem solid #2a3a45' : 'none',
                        '--image-margin': heavyBorder ? '2rem -2rem -2rem 2rem' : '0',
                        '--container-margin': heavyBorder ? '0 2rem 2rem 0' : '0',
                    }}
                >
                    <div className='draft-image-options__content__container__image-container'>
                        <img
                            className='draft-image-options__content__container__image-container__image'
                            src={!!imageData.internal ? URL_PICTURES_FULL + '/' + imageData.image : imageData.image}
                            alt='image'
                        />
                    </div>
                    <div className="draft-image-options__content__container__align">{renderAlignOptions()}</div>
                </div>
                {
                    !!caption &&
                    <div className='draft-image-options__content__caption'>{caption}</div>
                }
            </div>
        )
    }

    const renderRight = () => {
        return (
            <>
                <CardItem>
                    <Input
                        className='draft-image-options__content__input'
                        placeholder='Caption'
                        name='caption'
                        iconLeft='fas fa-comment'
                        value={caption}
                        onChange={e => setCaption(e.target.value)}
                        visualVariables={{height: '4rem'}}
                    />
                    <Switch
                        value={showCaption}
                        onChange={bool => setShowCaption(bool)}
                        className='draft-image-options__content__switch'
                        title='Show caption'
                        description={"Set on if you want caption to be visible under the image"}
                    />
                </CardItem>
                <CardItem>
                    <Switch
                        value={isLink}
                        onChange={bool => setIsLink(bool)}
                        className='draft-image-options__content__switch'
                        title='Use image as link'
                        description={"Defines if the image is represented as a link"}
                    />
                </CardItem>
                <CardItem>
                    {
                        !!isLink &&
                        <Input
                            className='draft-image-options__content__input'
                            placeholder='Attached link'
                            name='attachedLink'
                            iconLeft='fas fa-link'
                            value={attachedLink}
                            onChange={e => setAttachedLink(e.target.value)}
                            visualVariables={{height: '4rem'}}
                            disabled={!isLink}
                            autofocus
                        />
                    }
                    {
                        !!isLink &&
                        <Switch
                            value={nofollow}
                            onChange={bool => setNofollow(bool)}
                            className='draft-image-options__content__switch'
                            title='Add rel="nofollow" attribute'
                            description={"If enabled allows to prevent search engines to crawl through linked sites"}
                        />
                    }
                    <Switch
                        value={limitHeight}
                        onChange={bool => setLimitHeight(bool)}
                        className='draft-image-options__content__switch'
                        title='Limit image height to 700 px'
                        description={"Enable if image has small width and large height, so it will not take all available page height"}
                    />
                    <Switch
                        value={lightBorder}
                        onChange={bool => {
                            setLightBorder(bool);
                            setHeavyBorder(!bool ? false : heavyBorder)
                        }}
                        className='draft-image-options__content__switch'
                        title='Add thin border'
                        description={"Consider enabling if image is a screenshot with white background"}
                    />
                    <Switch
                        value={heavyBorder}
                        onChange={bool => setHeavyBorder(bool)}
                        className='draft-image-options__content__switch'
                        disabled={!lightBorder}
                        disabledMessage='Enable thin border to unlock this option'
                        title='Add full border'
                        description={"When a thin border is not enough"}
                    />
                    <div className='draft-image-options__bottom'>
                        <Button
                            title='Insert image'
                            onClick={() => {
                                const data = {
                                    image: imageData.image,
                                    internal: imageData.internal,
                                    align,
                                    caption,
                                    showCaption,
                                    limitHeight,
                                    lightBorder,
                                    heavyBorder,
                                    isLink,
                                    attachedLink,
                                    nofollow,
                                };
                                insertBlock(data, 'img', context, entityKey);
                                store.dispatch(removeLastModalLayer());
                            }}
                            visuals={{borderRadius: '1.8rem'}}
                        />
                    </div>
                </CardItem>
            </>
        )
    }

    return (
        <div className='draft-image-options'>
            <div className='draft-image-options__content'>
                {renderLeft()}
                <div className='draft-image-options__content__right-side'>
                    {renderRight()}
                </div>
            </div>
        </div>
    )
}