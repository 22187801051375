import React, { createContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export const FoldingContentContext = createContext(undefined);

function FoldingContent({
                            children,
                            folded,
                            style,
                            layoutDimensions,
                            exactHeight
                        }) {

    const content = useRef();
    const height = useRef();

    const [recalculate, setRecalculate] = useState(false); // from children

    const setHeight = () => {
        if (!folded) height.current = content.current?.offsetHeight;
    }
    // get initial height value
    useEffect(() => {setTimeout(setHeight, 0);}, []);
    // recalculate height
    useEffect(() => {
        setTimeout(setHeight, typeof recalculate === 'number' ? recalculate : 0);
        if (typeof recalculate === 'number' || !!recalculate) setTimeout(
            () => setRecalculate(false),
            typeof recalculate === 'number' ? recalculate : 0
        );
    }, [layoutDimensions.height, layoutDimensions.width, recalculate]);

    const context = {
        recalculateHeight: timeout => setRecalculate(timeout || true),
    }

    const collapse = folded ? ` fc-container--folded` : '';

    return (
        <div
            className={`fc-container${collapse}`}
            style={{
                ...style,
                '--content-height': `${height?.current * (exactHeight ? 1 : 2) || 4000}px`, // * 2 to avoid lag when user performs fast changing of something big
            }}
            data-folded={!!folded}
        >
            <div ref={content}>
                <FoldingContentContext.Provider value={context}>
                    {children}
                </FoldingContentContext.Provider>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    layoutDimensions: state.UI.layoutDimensions,
});

export default connect(mapStateToProps)(FoldingContent)

FoldingContent.propTypes = {
    folded: PropTypes.bool,
    style: PropTypes.object,
}