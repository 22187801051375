import React from 'react'
import PropTypes from 'prop-types'

export default class RangeSlider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            values: null,
            steps: null,
            max: 0,
            min: 0,
            start: 0,
            end: 0,
            length: 0,
        }
    }

    calculatePositions = (max, min, handledValues) => {

        let { value } = this.props;
        let start = 0, end = 0, length = 0;
        let findValueIndex = _v => handledValues.findIndex(v => v === _v);
        let findPosition = (val, diff) => val * 100 / diff;
        let diff = handledValues.length - 1;


        if (!!value && !!Array.isArray(value)) {
            start = findPosition(findValueIndex(value[0]), diff);
            end = findPosition(findValueIndex(value[1]), diff);
        } else if (!!value) {
            end = findPosition(findValueIndex(value), diff)
        }

        this.setState({
            start: start,
            end: end,
            length: end - start,
        })
    }

    fixValue = handledValues => {

        if (!this.props.autofix && 'autofix' in this.props) return;

        let { value } = this.props;
        let storedValue = value;
        let minValue = handledValues[0];
        let maxValue = handledValues[handledValues.length - 1];

        if (!!Array.isArray(value)) {
            value[0] = value[0] < minValue ? minValue : value[0];
            value[1] = value[1] > maxValue || value[1] < value[0] ? maxValue : value[1];
        } else {
            value = value < minValue ? minValue : value;
            value = value > maxValue ? maxValue : value;
        }

        if (storedValue === value) return;

        this.props.onChange({
            name: this.props.name,
            value: value,
        })
    }

    handleChange = e => {

        if (!!this.props.disabled) return;

        let { start, end, values } = this.state;
        let { value } = this.props;
        let scaleWidth = this.scale.offsetWidth;
        let scalePositionStart = this.scale.getBoundingClientRect().left;
        let cursorPosition = e.clientX - scalePositionStart;
        let valueRelativePosition = cursorPosition * 100 / scaleWidth;
        let relativeValue = valueRelativePosition * (values.length - 1) / 100;
        let valueIndex = Math.round(relativeValue);
        let finalValue = null;

        if (
            cursorPosition < 0 || cursorPosition > scaleWidth
            || !!Array.isArray(value) && values[valueIndex] < value[0]
            || typeof value === 'number' && (value < values[0] || value > value[value.length - 1])
        ) return;

        if (!!Array.isArray(value)) {
            finalValue = [value[0], values[valueIndex]]
        } else {
            finalValue = values[valueIndex]
        }

        this.props.onChange({
            name: this.props.name,
            value: finalValue,
        });
    }

    start = e => {
        document.addEventListener('mousemove', this.move);
        // document.addEventListener('touchmove', this.move);
        this.handleChange(e);
    }

    move = e => {
        e.preventDefault();
        this.handleChange(e);
    }

    end = e => {
        document.removeEventListener('mousemove', this.move);
        // document.removeEventListener('touchmove', this.move);
    }

    scroll = e => {
        e.preventDefault();
        if (!e.wheelDelta || !!this.props.disabled) return;

        let { start, end, values } = this.state;
        let { value, step } = this.props;
        let up = e.wheelDelta > 0;
        let newValue = value[1] || value;
        let finalValue = null;
        let availablePositions = [];

        for (let i = 0; i < values.length; i++) {
            availablePositions.push(i * 100 / (values.length - 1))
        }

        let currentStartPositionIndex = availablePositions.findIndex(i => i === start);
        let currentEndPositionIndex = availablePositions.findIndex(i => i === end);

        if (up) {
            newValue = values[currentEndPositionIndex + 1];
            if (!newValue) return;
        } else {
            newValue = values[currentEndPositionIndex - 1];
            if (!newValue && newValue !== 0 || newValue < values[currentStartPositionIndex]) return;
        }

        if (!!Array.isArray(value)) {
            finalValue = [value[0], newValue]
        } else {
            finalValue = newValue
        }

        this.props.onChange({
            name: this.props.name,
            value: finalValue,
        });
    }

    onHover = e => {
        if (!!this.props.scroll) {
            this.slider.addEventListener('mousewheel', this.scroll, { passive: false });
            this.slider.addEventListener('mousewheel', this.scroll, { passive: false });
        }
    }

    onHoverEnd = e => {
        if (!!this.props.scroll) {
            this.slider.removeEventListener('mousewheel', this.scroll);
            this.slider.removeEventListener('mousewheel', this.scroll);
        }
    }

    componentDidMount() {

        document.addEventListener('mouseover', this.onHover)
        document.addEventListener('mouseleave', this.onHoverEnd);
        this.slider.addEventListener('mousedown', this.start);
        // this.slider.addEventListener('touchstart', this.start);
        document.addEventListener('mouseup', this.end);
        // document.addEventListener('touchend', this.end);

        let { min, max, step, values } = this.props;
        let newValues = [], steps = [];

        if (!!values) {
            newValues = values;
            min = 0;
            max = values.length;
        } else {
            for (let i = !!min ? min : 0; i <= max; i += step ? step : 1) {
                newValues.push(parseFloat(i.toPrecision(12))); // fix 0.1 + 0.2
            }
        }
        // calculate steps
        let findPosition = (val, diff) => val * 100 / diff;
        let findStepIndex = _v => newValues.findIndex(v => v === _v);
        newValues.map(v => steps.push(findPosition(findStepIndex(v), newValues.length - 1)));

        this.calculatePositions(max, min, newValues);
        this.fixValue(newValues);

        this.setState({
            isMounted: true,
            values: newValues,
            max: max,
            min: min,
            steps: steps,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { value } = this.props;
        let { max, min, values, isMounted } = this.state;

        if (!!isMounted && value !== prevProps.value) {
            this.calculatePositions(max, min, values);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseover', this.onHover);
        document.removeEventListener('mouseleave', this.onHoverEnd);
        this.slider.removeEventListener('mousedown', this.start);
        this.slider.removeEventListener('touchstart', this.start);
        document.removeEventListener('mouseup', this.end);
        document.removeEventListener('touchend', this.end);
    }

    renderFillBar = () => {
        let { start, length } = this.state;
        return (
            <div
                style={{
                    '--fill-start': start + '%',
                    '--fill-width': length + '%',
                }}
                className='range-slider__slider__fill'
            />
        )
    }

    renderSteps = () => {

        let { values, steps, max, min } = this.state;
        let {step} = this.props;

        if (!steps) return null;

        return (
            <div className='range-slider__slider__steps'>
                {
                    steps.map((s, i) => {
                        if (steps.length > 60 && i % (step >= 10 ? step / 2 : step * 2) !== 0 && i !== steps.length - 1) return null;
                        return (
                            <div
                                style={{ '--step-position': s + '%', }}
                                className='range-slider__slider__steps__step'
                                key={i}
                            >
                                {/*<i>{values[i]}</i>*/}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderStartPoint = () => {

        let { start } = this.state;
        let { value } = this.props;

        if (!!value && !!Array.isArray(value)) {
            return (
                <div
                    className='range-slider__slider__point'
                    style={{
                        '--point-position': start + '%',
                    }}
                />
            )
        }
    }

    renderEndPoint = () => {
        let { end } = this.state;
        return (
            <div
                className='range-slider__slider__point'
                style={{
                    '--point-position': end + '%',
                }}
            />
        )
    }

    render() {

        let { value, unit, hideValue, className, minWidthValue, disabled } = this.props;

        return (
            <div
                className={`range-slider${disabled ? ' range-slider--disabled' : ''}${className ? ' ' + className : ''}`}
            >
                <div className='range-slider__slider' ref={el => this.slider = el}>
                    <div className='range-slider__slider__base' ref={el => this.scale = el}/>
                    {this.renderFillBar()}
                    {this.renderSteps()}
                    {this.renderStartPoint()}
                    {this.renderEndPoint()}
                </div>
                {
                    !hideValue &&
                    <div
                        className='range-slider__value'
                        style={{...(minWidthValue ? {'--rs-min-width-value' : minWidthValue} : {})}}
                    >
                        {!!Array.isArray(value) ? `${value[0]} - ${value[1]}` : value} <span>{unit}</span>
                    </div>
                }
            </div>
        );
    }
}

RangeSlider.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]).isRequired, // 1 / 3 / 10 / [10, 20] / [20, 100]
    values: PropTypes.arrayOf(PropTypes.number),
    minWidthValue: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    unit: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hideValue: PropTypes.bool,
    disabled: PropTypes.bool,
    autofix: PropTypes.bool,
    scroll: PropTypes.bool,
}