import React, { useState } from 'react'
import Switch from '../../../../Switch';
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import Input from "../../../../Input";
import { CardItem } from "../../../../card/Card";
import Button from "../../../../ripple/Button";
import { insertDecorator } from "../_insert";
import { RichUtils } from "draft-js";
import { dispatchNotification } from "../../../../../layouts/app/components/notificationSystem/notifications";

export default function DialogueLink({
                                         context,
                                         data,
                                         selectedText,
                                     }) {

    const [url, setUrl] = useState(data?.url || '');
    const [newTab, setNewTab] = useState('newTab' in (data || {}) ? data.newTab : true);
    const [nofollow, setNofollow] = useState('nofollow' in (data || {}) ? data.nofollow : true);
    const [externalLink, setExternalLink] = useState('externalLink' in (data || {}) ? data.externalLink : false);

    const onSubmit = () => {

        if (!url?.length) {
            dispatchNotification({
                message: 'Please enter URL first',
                type: 'warning'
            });
            return
        }
        const data = {
            url: url,
            newTab: newTab,
            externalLink: externalLink,
            nofollow: nofollow
        }
        insertDecorator(data, 'LINK', context);
        store.dispatch(removeLastModalLayer())
    };

    return (
        <div className='add-draft-link'>
            <CardItem>
                <Input
                    value={url}
                    name='url'
                    placeholder='Link'
                    onChange={e => setUrl(e.target.value)}
                />
            </CardItem>
            <CardItem>
                <Switch
                    value={newTab}
                    className='add-draft-link__content__switch'
                    onChange={bool => setNewTab(bool)}
                    description="Please consider enabling this option if link is referred to an external resource"
                    title='Open link in new tab'
                />
                <Switch
                    value={nofollow}
                    className='add-draft-link__content__switch'
                    onChange={bool => setNofollow(bool)}
                    description="If enabled allows to prevent search engines to crawl through linked sites"
                    title='Add "nofollow" argument'
                />
                <Switch
                    value={externalLink}
                    className='add-draft-link__content__switch'
                    onChange={bool => setExternalLink(bool)}
                    description="This option is just for end user to visually separate internal and external links"
                    title='Mark link as external'
                />
            </CardItem>
            <CardItem>
                <a
                    href={url}
                    className='add-draft-link__content__preview'
                    target='_blank'
                >
                    {selectedText}
                    {
                        externalLink &&
                        <i className="fas fa-external-link-alt add-draft-link__content__preview__external-icon"/>
                    }
                </a>
            </CardItem>
            <CardItem className='add-draft-link__bottom'>
                <Button
                    title='Remove link'
                    displayType='text'
                    onClick={() => {
                        const selection = context.editorState.getSelection();
                        context.setEditorState(RichUtils.toggleLink(context.editorState, selection, null));
                        setUrl('');
                        setNewTab(true);
                        setNofollow(true);
                        setExternalLink(false);
                    }}
                    visuals={{borderRadius: '1.8rem'}}
                />
                <Button
                    title='Confirm'
                    onClick={onSubmit}
                    visuals={{borderRadius: '1.8rem'}}
                />
            </CardItem>
        </div>
    );
}