import React, { useState } from 'react'
import Switch from '../../../../Switch';
import Button from "../../../../ripple/Button";
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import Input from "../../../../Input";
import { CardItem } from "../../../../card/Card";
import { insertBlock } from "../_insert";
import { dispatchNotification } from "../../../../../layouts/app/components/notificationSystem/notifications";

export default function DialogueVideoYoutube({videoData = {}, entityKey, context}) {

    const [linkSource, setLinkSource] = useState(videoData.linkSource || '');
    const [videoID, setVideoID] = useState(videoData.videoID || '');
    const [aspectRatio, setAspectRatio] = useState(videoData.aspectRatio || [16, 9]);
    const [lightBorder, setLightBorder] = useState('lightBorder' in videoData ? videoData.lightBorder : false);
    const [heavyBorder, setHeavyBorder] = useState('heavyBorder' in videoData ? videoData.heavyBorder : false);
    const [error, setError] = useState('');

    const onChange = e => {

        setError('');

        let {value} = e.target;
        let videoID = '';
        let error = '';

        try {
            // get full string after v=
            videoID = value.split('v=')[1];
            // find additional arguments
            let ampersandPosition = videoID.indexOf('&');
            // check if video id is not the end of string
            if (ampersandPosition !== -1) {
                videoID = videoID.substring(0, ampersandPosition); // cut the excessive part of url
            }

        } catch {
            error = "Link doesn't seem to be affiliated with YouTube"
        }

        setLinkSource(value);
        setVideoID(videoID);
        setError(error);
    };

    const renderAspectRatioButtons = () => {

        let ratios = [[16, 9], [16, 10], [4, 3], [3, 2], [8, 5], [1, 1]];

        return ratios.map((ratio, index) => {
            return (
                <Button
                    title={ratio[0] + 'x' + ratio[1]}
                    displayType={aspectRatio[0] === ratio[0] && aspectRatio[1] === ratio[1] ? 'outlined' : 'text'}
                    onClick={() => setAspectRatio(ratio)}
                    key={index}
                    visuals={{height: '3rem', borderRadius: '1.5rem'}}
                />
            )

        })
    };

    const prepareData = e => {
        e.preventDefault();

        if (!!error || !videoID) {
            dispatchNotification({
                message: 'Please enter valid link first',
                type: 'warning',
            });
        } else {
            const data = {
                videoID,
                aspectRatio,
                lightBorder,
                heavyBorder,
                linkSource,
            };
            insertBlock(data, 'video', context, entityKey);
            store.dispatch(removeLastModalLayer());
        }
    }

    const thinBorder = lightBorder ? ' draft-video-preview--thin' : '';
    const fullBorder = heavyBorder ? ' draft-video-preview--full' : '';

    return (
        <form onSubmit={prepareData}>
            <div>
                <CardItem>
                    <Input
                        placeholder='Paste YouTube link'
                        id='link-source'
                        name='linkSource'
                        className='add-draft-image__source__link-input'
                        value={linkSource}
                        isValid={!error ? undefined : false}
                        error={error}
                        onChange={onChange}
                        autofocus
                    />
                </CardItem>
                <CardItem>
                    <div
                        className={`draft-video-preview${thinBorder}${fullBorder}`}
                        style={{'--aspect-ratio': aspectRatio[1] / aspectRatio[0] * 100 + '%'}}
                    >
                        <div className='draft-video-preview__overlay'>
                            <iframe
                                className='draft-video-preview__overlay__preview'
                                src={`https://www.youtube.com/embed/${videoID}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                    <div className='draft-video-aspect-buttons'>
                        {renderAspectRatioButtons()}
                    </div>
                </CardItem>
                <CardItem>
                    <Switch
                        value={lightBorder}
                        onChange={bool => {
                            setLightBorder(bool);
                            setHeavyBorder(!bool ? false : heavyBorder)
                        }}
                        className='draft-video-switch'
                        title='Add thin border'
                        description={"Consider enabling if video background is filled with white color or blends into the background"}
                    />
                    <Switch
                        value={heavyBorder}
                        onChange={bool => setHeavyBorder(bool)}
                        className='add-draft-video__switch'
                        disabled={!lightBorder}
                        disabledMessage='Enable thin border to unlock this option'
                        title='Add full border'
                        description={"Enable for the video to take user attention"}
                    />
                </CardItem>
            </div>
            <CardItem
                notContained
                className='draft-video-bottom'
            >
                <Button
                    title='Submit'
                    type='submit'
                    disabled={!!error}
                    visuals={{borderRadius: '1.8rem'}}
                />
            </CardItem>
        </form>
    )
}