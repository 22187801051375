import React from 'react'
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueButton from "./DialogueButton";
import { buildStyleVariables } from "../../../../ripple/_helpers";

export default function BlockButton({
                                        converted, // when converting to html
                                        data,
                                        context,
                                        entityKey
                                    }) {

    const baseClass = 'ripple-button';

    const config = {
        baseClass,
        displayType: !!data.displayType ? data.displayType : 'contained',
        iconPosition: data.iconPosition,
        faIcon: data.icon,
        showTitle: data.iconPosition !== 'center' && !!data.title,
    };

    let unified = [
        'borderRadius', 'height', 'width', 'animationDuration', 'padding', 'margin', 'fontSizeIcon', 'fontSizeText'
    ];

    const height = !isNaN(parseFloat(data.visuals?.height)) ? parseFloat(data.visuals?.height) : 3.6;
    const round = exp => Math.round(exp * 10) / 10;
    const calculatedVisuals = {
        ...data.visuals,
        ...{
            borderRadius: data.mods?.borderRadius !== undefined ? round((height / 2) * data.mods.borderRadius) + 'rem' : undefined,
            fontSizeIcon: data.mods?.fontSizeIcon !== undefined ? round((height / 2) * data.mods.fontSizeIcon) - 0.2 + 'rem' : undefined,
            fontSizeText: data.mods?.fontSizeText !== undefined ? round((height / 2) * data.mods.fontSizeText) - 0.4 + 'rem' : undefined,
        }
    }

    const visualVariables = buildStyleVariables(calculatedVisuals, unified, 'button');

    return (
        <div className={`draft-button-block`}>
            <a
                className={`draft-button-block__link`}
                style={{
                    borderRadius: data.mods?.borderRadius !== undefined ? round((height / 2) * data.mods.borderRadius) + 'rem' : height / 2 + 'rem',
                }}
                href={data.link}
                target={data.target}
            >
                <div
                    className={`${config.baseClass}-container ${config.baseClass}-container--${config.displayType}`}
                    style={{...visualVariables}}
                >
                    <button
                        className={`${config.baseClass} ${config.baseClass}--${config.displayType} draft-button-block__link__button`}
                        aria-label={data['aria-label']}
                        type={'button'}
                        draggable={'false'}
                        tabIndex={-1}
                    >
                        {
                            !!config.faIcon &&
                            <i
                                className={`${config.faIcon} ${config.baseClass}__icon ${config.baseClass}__icon-${config.iconPosition}`}
                                aria-hidden="true"
                            />
                        }
                        {!!config.showTitle && <p className={`${config.baseClass}__title`}>{data.title}</p>}
                    </button>
                </div>
            </a>
            {
                !converted && !context?.readOnly &&
                <div className={`draft-custom-edit-button`}>
                    <div className={`draft-custom-edit-button__container`}>
                        <i className={`fas fa-cog draft-custom-edit-button__container__icon`}/>
                        <div
                            className={`draft-custom-edit-button__container__cover`}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                showModalDefault({
                                    component: (
                                        <DialogueButton
                                            context={context}
                                            data={data}
                                            entityKey={entityKey}
                                        />
                                    ),
                                    title: 'Add button',
                                    maxWidth: '1000px',
                                })
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
}