import React, { createContext, useEffect, useRef, useState } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import Draft, {
    convertFromRaw,
    convertToRaw,
    Editor,
    EditorState,
    RichUtils,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import ControlPanel from './components/control/ControlPanel';
import { handleDraftEditorPastedText, onDraftEditorCopy, onDraftEditorCut, } from "draftjs-conductor";
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import Immutable from 'immutable'
import DraftToHTML from './export/DraftHTML';
import {
    CUSTOM_STYLE_MAP,
} from "./helpers/styles";
import defineBlockRender from "./components/blocks/_render";
import defineDecoratorRender from "./components/decorators/_render";
import {
    createFontBackgroundColorVariables,
    createFontColorVariables,
    setFontBackgroundColors,
    setFontColors
} from "./helpers/colors";
import { blockStyleFn } from "./helpers/blockTypes";
import { handleKeyCommand, keyBindingFn } from "./helpers/keys";
import CodeUtils from "draft-js-code";

let updatePreviewTimeout = null;
export const DraftContext = createContext(undefined);

function DraftEditor({
                         content, controls, readOnly, convertTo,
                         className, styles, defaultFontSize,
                         dark,
                         updateContent,
                         isCodeEditor,
                         ...props
                     }) {

    let editorRef = useRef();

    const decorators = defineDecoratorRender();

    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorators));

    const [showPreview, setShowPreview] = useState(false);
    const [updatePreviewContent, setUpdatePreviewContent] = useState(false);

    const [fontColorsVars, setFontColorsVars] = useState({});
    const [fontColorsMap, setFontColorsMap] = useState({});
    const [fontBackgroundColorsVars, setFontBackgroundColorsVars] = useState({});
    const [fontBackgroundColorsMap, setFontBackgroundColorsMap] = useState({});
    // init colors mapping
    useEffect(() => {
        setFontColorsMap(setFontColors())
        setFontBackgroundColorsMap(setFontBackgroundColors())
    }, []);
    // init colors passed to styles
    useEffect(() => {
        setFontColorsVars(createFontColorVariables(dark));
        setFontBackgroundColorsVars(createFontBackgroundColorVariables(dark));
    }, [dark]);
    // init content and update by props
    useEffect(() => {
        switch (true) {
            case convertTo === 'text' && !!content:
                setEditorState(EditorState.createWithText(content, decorators))
                break;
            case 'text' in (content?.blocks?.[0] || {}):
                // setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(content))), decorators))
                setEditorState(EditorState.createWithContent(convertFromRaw(content), decorators))
                break;
            default: // if no valid format in new dashboard item found create new empty document
                setEditorState(EditorState.createEmpty(decorators))
                break;
        }
    }, [updateContent]);
    // reset update preview content
    useEffect(() => {
        if (!!updatePreviewContent) setUpdatePreviewContent(false);
    }, [updatePreviewContent]);

    const getConvertedState = editorState => {
        return !convertTo
            ? convertToRaw(editorState.getCurrentContent())
            : editorState.getCurrentContent().getPlainText()
    }

    const onChange = _editorState => {

        clearTimeout(updatePreviewTimeout);

        if (!!showPreview) {
            updatePreviewTimeout = setTimeout(() => setUpdatePreviewContent(true), 2000);
        }

        const converted = getConvertedState(_editorState);

        if (
            isCodeEditor && (
                !converted.blocks.some(b => b.type === 'code-block')
                // || converted.blocks.filter(b => b.type === 'code-block')?.length > 1
            )
        ) {
            setEditorState(EditorState.set(editorState, {decorator: decorators}));
            return
        }

        // setEditorState(editorState);
        setEditorState(EditorState.set(_editorState, {decorator: decorators}));
        if (props.onChange) props.onChange(converted);
    };

    const onSubmit = () => {

        if (!props.onSubmit) return;

        setUpdatePreviewContent(true);

        if (!!props.onSubmit) {
            const converted = getConvertedState(editorState);
            props.onSubmit(converted);
        }
    }

    const context = {
        editorState,
        setEditorState,
        onChange,
        onSubmit: props.onSubmit ? onSubmit : undefined,
        readOnly,
        focus: () => setTimeout(() => editorRef.current.focus(), 0),
    }

    const customStyleMap = {...CUSTOM_STYLE_MAP, ...fontBackgroundColorsMap, ...fontColorsMap};
    const style = {...fontColorsVars, ...fontBackgroundColorsVars};

    const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(Immutable.Map({
        'code-block': {
            element: data => React.Children.map(data.children, child => (
                <pre><code className={data.className}>{child}</code></pre>
            )),
        }
    }));

    const split = showPreview ? ' content-editor__area--split' : '';

    return (
        <DraftContext.Provider value={context}>
            <div
                className={`content-editor${className ? ' ' + className : ''}`}
                style={style}
            >
                {
                    !readOnly &&
                    <ControlPanel
                        controls={controls}
                        defaultFontSize={defaultFontSize}
                        stylesMap={customStyleMap}
                        showPreview={showPreview}
                        setShowPreview={setShowPreview}
                    />
                }
                <div className={`content-editor__area${split}`}>
                    <Editor
                        // decorators={decorators}
                        editorState={editorState}
                        onChange={onChange}
                        readOnly={readOnly}
                        // styles
                        customStyleMap={customStyleMap}
                        // default blocks
                        blockStyleFn={block => blockStyleFn(block, styles)}
                        // blockRenderMap={extendedBlockRenderMap}
                        // atomic custom blocks
                        blockRendererFn={block => defineBlockRender({block, context})}
                        // keys combinations
                        handleKeyCommand={(command, editorState) => handleKeyCommand(command, editorState, context)}
                        keyBindingFn={(e) => keyBindingFn(e, context)}
                        handleReturn={event => {
                            switch (true) {
                                case !!CodeUtils.hasSelectionInBlock(editorState):
                                    onChange(CodeUtils.handleReturn(event, editorState));
                                    return 'handled';
                                case isSoftNewlineEvent(event):
                                    onChange(RichUtils.insertSoftNewline(editorState));
                                    return 'handled';
                                default:
                                    return 'not-handled';
                            }
                        }}
                        // copy-paste
                        handlePastedText={(text, html, editorState) => {
                            const newState = handleDraftEditorPastedText(html, editorState);
                            if (newState) {
                                onChange(newState);
                                return true;
                            }
                            return false;
                        }}
                        onCopy={onDraftEditorCopy}
                        onCut={onDraftEditorCut}
                        //
                        ref={editorRef}
                    />
                    {
                        showPreview &&
                        <div className='content-editor__area__preview'>
                            <div className='content-editor__area__preview__content'>
                                <DraftToHTML
                                    content={convertToRaw(editorState.getCurrentContent()) || null}
                                    styles={styles}
                                    className={className}
                                    updateContent={updatePreviewContent}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </DraftContext.Provider>
    );
}

const mapStateToProps = state => ({dark: state.UI.theme.dark});

export const DraftPropTypes = {
    content: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    styles: PropTypes.object,
    controls: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOf([
                'fontSize',
                'colorFont',
                'colorBackgroundFont',
                'styling',
                'alignment',
                'headings',
                'blockTypes',
                'link',
                'image',
                'youtube',
                'preview'
            ])
        ),
        PropTypes.bool,
    ]),
    readOnly: PropTypes.bool,
    convertTo: PropTypes.oneOf(['text' || undefined]),
    defaultFontSize: PropTypes.string,
    updateContent: PropTypes.bool,
}

DraftEditor.propsTypes = DraftPropTypes;

export default connect(mapStateToProps)(DraftEditor)