import React from 'react';

export const ControlBlock = props => {
    let {children} = props;

    return (
        <div className='draft-control-panel__container__block'>{children}</div>
    )
}

export function ControlButton({active, icon, iconColor, text, className, style, onClick}) {
    const activeClass = active ? ' draft-ctrl-button__button--active' : '';
    return (
        <div className={`draft-ctrl-button${className ? ' ' + className : ''}`} style={style}>
            <button
                type='button'
                className={`draft-ctrl-button__button ${activeClass}`}
                onClick={onClick}
                style={{'--ctrl-icon-color' : iconColor,}}
            >
                {!!icon && <i className={`${icon} draft-ctrl-button__button__icon`}/>}
                {!!text && <p className='draft-ctrl-button__button__text'>{text}</p>}
            </button>
        </div>
    )
}