import React from 'react'


export function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        character => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
        },
        callback
    );
}

export function BlockLink(props) {

    let source = !!Object.keys(props.data || {}).length
        ? props.data
        : props.contentState.getEntity(props.entityKey).getData();
    let {url, newTab, externalLink, nofollow} = source;

    return (
        <a
            className='draft-link'
            href={url}
            target={`${newTab ? '_blank' : '_self'}`}
            rel={!!nofollow ? 'nofollow' : undefined}
        >
            {props.children}
            {externalLink && <i className="fas fa-external-link-alt draft-link__external-icon"/>}
        </a>
    )
}