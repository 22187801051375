import React from 'react'
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueCode from "./DialogueCode";
import { getPlainTextFromContent } from "../../../export/exports";
import parse from 'html-react-parser';
import { createHighlighter } from "shiki";
import {
    DRAFT_CODE_LANGUAGES,
    DRAFT_CODE_LANGUAGES_DEPRECATED, DRAFT_CODE_THEME_DARK,
    DRAFT_CODE_THEME_LIGHT
} from "../../../../../settings/draft";

const highlighter = await createHighlighter({
    themes: [DRAFT_CODE_THEME_LIGHT, DRAFT_CODE_THEME_DARK],
    langs: DRAFT_CODE_LANGUAGES.map(l => l.code),
})

export default function BlockCode({
                                      converted, // when converting to html
                                      data,
                                      context,
                                      entityKey
                                  }) {

    const isEditor = !converted && !context?.readOnly;

    let language = data?.language;
    // translate prismjs languages to shiki
    if (data?.language in DRAFT_CODE_LANGUAGES_DEPRECATED) language = DRAFT_CODE_LANGUAGES_DEPRECATED[data?.language];
    // check language support
    if (!DRAFT_CODE_LANGUAGES.some(l => l.code === language)) language = undefined;

    const createHighlightedCode = () => {
        return highlighter.codeToHtml(
            getPlainTextFromContent(data?.content || undefined),
            {
                lang: language,
                themes: {
                    light: DRAFT_CODE_THEME_LIGHT,
                    dark: DRAFT_CODE_THEME_DARK,
                }
            })
    }

    const languageData = DRAFT_CODE_LANGUAGES.find(l => data.language === l.code);

    return (
        <div className={`draft-code-block`}>
            <div id={`draft-code-${data.id}`} className={`draft-code-block__container`}>
                {!!data?.content && parse(createHighlightedCode())}
            </div>
            {
                (!data.hideCopy || !data.hideLanguage && language) &&
                <div className='draft-code-block__additional'>
                    {
                        !data.hideLanguage && language &&
                        <p className='draft-code-block__additional__language'>{languageData?.name || data.language}</p>
                    }
                    {
                        !data.hideCopy &&
                        <button
                            className={`draft-code-block__additional__button`}
                            aria-label={data['aria-label']}
                            type={'button'}
                            draggable={'false'}
                            id={`draft-copy-code-${data.id}`}
                        >
                            <i className={`fas fa-copy`} aria-hidden="true"/>
                        </button>
                    }
                </div>
            }
            {
                isEditor &&
                <div className={`draft-custom-edit-button`}>
                    <div className={`draft-custom-edit-button__container`}>
                        <i className={`fas fa-cog draft-custom-edit-button__container__icon`}/>
                        <div
                            className={`draft-custom-edit-button__container__cover`}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                showModalDefault({
                                    component: (
                                        <DialogueCode
                                            context={context}
                                            data={data}
                                            entityKey={entityKey}
                                        />
                                    ),
                                    title: 'Add code block',
                                    maxWidth: '1900px',
                                })
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
}