import React from 'react'
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueVideoYoutube from "./DialogueVideoYoutube";

export default function BlockVideoYoutube({
                                              converted, // when converting to html
                                              videoData, context, entityKey
}) {

    const {videoID, aspectRatio, lightBorder, heavyBorder} = videoData;
    const thinBorder = lightBorder ? ' video-draft-block--thin' : '';
    const fullBorder = heavyBorder ? ' video-draft-block--full' : '';

    return (
        <div
            className={`video-draft-block${thinBorder}${fullBorder}`}
            style={{'--aspect-ratio': aspectRatio[1] / aspectRatio[0] * 100 + '%'}}
        >
            <div className='video-draft-block__overlay'>
                <iframe
                    className='video-draft-block__overlay__preview'
                    src={`https://www.youtube.com/embed/${videoID}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            {
                !converted && !context.readOnly &&
                <div
                    className="image-draft-block__edit-button"
                    onClick={e => {
                        e.preventDefault();
                        showModalDefault({
                            component: (
                                <DialogueVideoYoutube
                                    context={context}
                                    videoData={videoData}
                                    entityKey={entityKey}
                                />
                            ),
                            title: 'Add YouTube video',
                            maxWidth: '700px',
                        })
                    }}
                >
                    <p className='image-draft-block__edit-button__title'>Edit <span>(double click)</span></p>
                    <i className="fas fa-edit image-draft-block__edit-button__icon"/>
                </div>
            }
        </div>
    );
}