import React, { useState } from 'react'
import Switch from '../../../../Switch';
import { dispatchNotification } from '../../../../../layouts/app/components/notificationSystem/notifications';
import Button from "../../../../ripple/Button";
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import Input from "../../../../Input";
import { CardItem } from "../../../../card/Card";
import { insertBlock } from "../_insert";
import BlockButton from "./BlockButton";
import FoldingBlock from "../../../../folding/FoldingBlock";
import RangeSlider from "../../../../RangeSlider";
import { DARK_THEME } from "../../../../../helpers/ui/ui";

export default function DialogueButton({entityKey, context, ...props}) {

    // const [linkSource, setLinkSource] = useState(data.linkSource || '');
    // const [videoID, setVideoID] = useState(data.videoID || '');
    // const [aspectRatio, setAspectRatio] = useState(data.aspectRatio || [16, 9]);
    // const [lightBorder, setLightBorder] = useState('lightBorder' in data ? data.lightBorder : false);
    // const [heavyBorder, setHeavyBorder] = useState('heavyBorder' in data ? data.heavyBorder : false);
    // const [error, setError] = useState('');

    const [data, setData] = useState({
        title: props.data?.title || 'Example button',
        icon: props.data?.icon || 'fas fa-chevron-right',
        iconPosition: props.data?.iconPosition || 'right',
        displayType: props.data?.displayType || 'contained',
        link: props.data?.link || '',
        target: props.data?.target || '_blank',
        visuals: props.data?.visuals || {},
        mods: {
            borderRadius: undefined,
            fontSizeIcon: undefined,
            fontSizeText: undefined,
        }
    });

    const ICON_POSITIONS = ['left', 'center', 'right'];
    const DISPLAY_TYPES = ['contained', 'outlined', 'text'];

    let messages = [];

    if (!data.link) {
        messages.push({
            message: `Link is required`,
            type: 'error',
            container: `draft-button`, block: `draft-button-main`, target: `draft-button-link`,
        })
    }

    const onChange = e => {
        const {name, value} = e.target;
        setData({...data, ...{[name]: value}})
    };

    const prepareData = e => {
        e.preventDefault();

        if (messages.some(m => m.type === 'error')) {
            dispatchNotification({
                message: 'Please check if data is valid first',
                type: 'error',
            });
        } else {
            insertBlock(data, 'button', context, entityKey);
            store.dispatch(removeLastModalLayer());
        }
    }

    const setVisuals = obj => {
        setData({
            ...data,
            ...{visuals: {...data.visuals, ...obj}}
        })
    };

    const setMod = obj => {
        setData({
            ...data,
            ...{mods: {...data.mods, ...obj}}
        })
    };

    const COLOR_OPTIONS = Object.keys(DARK_THEME).filter(color => (
        color.includes(`AccentBasic`)
        || color.includes(`AccentLight`)
        || color.includes(`AccentContrast`)
        || color.includes(`FontError`)
        || color.includes(`FontWarning`)
        || color.includes(`FontSuccess`)
        || color.includes(`FontInfo`)
    ));

    const height = !isNaN(parseFloat(data.visuals.height)) ? parseFloat(data.visuals.height) : 3.6;
    // const borderRadius = !isNaN(parseFloat(data.visuals.borderRadius)) ? parseFloat(data.visuals.borderRadius) : height / 2;
    const valueWidth = '10rem';

    // const modValue = Math.round((borderRadius / (height / 2)) * 10) / 10;

    // console.log('mod')
    // console.log(modValue)

    // console.log('br')
    // console.log(borderRadius)

    const renderLeft = () => (
        <form className={`draft-button-layout__form`}>
            <CardItem id={`draft-button-main`} messages={messages}>
                <Input
                    placeholder='Button title'
                    id='draft-button-title'
                    name='title'
                    value={data.title}
                    messages={messages}
                    onChange={onChange}
                    disabled={data.iconPosition === 'center'}
                    autofocus
                />
                <Input
                    placeholder='Button link'
                    id='draft-button-link'
                    name='link'
                    value={data.link}
                    messages={messages}
                    onChange={onChange}
                    autofocus
                />
                <Switch
                    style={{padding: '10px 16px'}}
                    value={data.target === '_blank'}
                    onChange={bool => {
                        setData({...data, ...{target: !!bool ? '_blank' : undefined}})
                    }}
                    className='stack-app-dash-switch'
                    title='Open in new tab'
                />
                <Input
                    placeholder='Button icon'
                    id='draft-button-icon'
                    name='icon'
                    value={data.icon}
                    messages={messages}
                    onChange={onChange}
                    autofocus
                />
                <div className={`draft-button-select-buttons`} style={{paddingTop: '10px'}}>
                    {
                        ICON_POSITIONS.map((position, index) => (
                            <Button
                                title={position.charAt(0).toUpperCase() + position.slice(1, position.length)}
                                iconRight={`fas fa-align-${position}`}
                                onClick={() => setData({...data, ...{iconPosition: position}})}
                                displayType={data.iconPosition === position ? 'outlined' : 'text'}
                                disabled={!data.icon}
                                key={index}
                            />
                        ))
                    }
                </div>
            </CardItem>
            <CardItem>
                <FoldingBlock title='Visuals' id='draft-button-visuals' folded={false}>
                    <div className={`draft-button-subtitle`}>Color</div>
                    <div className={`draft-button-colors`}>
                        {
                            COLOR_OPTIONS.map((color, index) => (
                                <button
                                    type='button'
                                    onClick={e => {
                                        e.preventDefault();
                                        setVisuals({
                                            colorBasic: [`var(--${color})`]
                                        })
                                    }}
                                    title={color}
                                    className={`draft-button-colors__color`}
                                    style={{backgroundColor: `var(--${color})`}}
                                    key={index}
                                >
                                    {
                                        (
                                            !data.visuals?.colorBasic?.[0] && color === 'colorAccentBasic'
                                            || data.visuals?.colorBasic?.[0].includes(color)
                                        ) &&
                                        <i className={`fas fa-check draft-button-colors__color__selected`}/>
                                    }
                                </button>
                            ))
                        }
                    </div>
                    <div className={`draft-button-subtitle`}>Display type</div>
                    <div className={`draft-button-select-buttons`} style={{paddingTop: '5px'}}>
                        {
                            DISPLAY_TYPES.map((type, index) => (
                                <Button
                                    title={type.charAt(0).toUpperCase() + type.slice(1, type.length)}
                                    onClick={() => setData({...data, ...{displayType: type}})}
                                    displayType={data.displayType === type ? 'outlined' : 'text'}
                                    key={index}
                                />
                            ))
                        }
                    </div>
                    <div className={`draft-button-subtitle`}>Height</div>
                    <RangeSlider
                        step={0.1}
                        min={3.1}
                        max={6}
                        unit={'rem'}
                        value={height}
                        onChange={result => setVisuals({height: result.value + 'rem'})}
                        minWidthValue={valueWidth}
                    />
                    <div className={`draft-button-subtitle`}>Border radius</div>
                    <RangeSlider
                        step={0.1}
                        min={0}
                        max={1}
                        unit={' / height'}
                        // value={modValue}
                        value={data.mods.borderRadius !== undefined ? data.mods.borderRadius : 1}
                        onChange={result => {
                            // console.log('new mod')
                            // console.log(result.value)
                            // const newBR =  Math.round((height / 2) * result.value * 10) / 10;
                            // const newBR =  (height / 2) * result.value;
                            // console.log('new br')
                            // console.log(newBR)
                            // setVisuals({borderRadiusMOD: newBR + 'rem'})
                            setMod({borderRadius: result.value})
                        }}
                        minWidthValue={valueWidth}
                    />
                    <div className={`draft-button-subtitle`}>Icon size</div>
                    <RangeSlider
                        step={0.01}
                        min={0.5}
                        max={1.01}
                        unit={' / height'}
                        // value={modValue}
                        value={data.mods.fontSizeIcon !== undefined ? data.mods.fontSizeIcon : 1}
                        onChange={result => {
                            // console.log('new mod')
                            // console.log(result.value)
                            // const newBR =  Math.round((height / 2) * result.value * 10) / 10;
                            // const newBR =  (height / 2) * result.value;
                            // console.log('new br')
                            // console.log(newBR)
                            // setVisuals({borderRadiusMOD: newBR + 'rem'})
                            setMod({fontSizeIcon: result.value})
                        }}
                        minWidthValue={valueWidth}
                    />
                    <div className={`draft-button-subtitle`}>Font size</div>
                    <RangeSlider
                        step={0.01}
                        min={0.6}
                        max={1.01}
                        unit={' / height'}
                        // value={modValue}
                        value={data.mods.fontSizeText !== undefined ? data.mods.fontSizeText : 1}
                        onChange={result => {
                            // console.log('new mod')
                            // console.log(result.value)
                            // const newBR =  Math.round((height / 2) * result.value * 10) / 10;
                            // const newBR =  (height / 2) * result.value;
                            // console.log('new br')
                            // console.log(newBR)
                            // setVisuals({borderRadiusMOD: newBR + 'rem'})
                            setMod({fontSizeText: result.value})
                        }}
                        minWidthValue={valueWidth}
                    />
                </FoldingBlock>
            </CardItem>
        </form>
    )

    return (
        <>
            <div className={`draft-button-layout`}>
                <div className={`draft-button-layout__preview`}>
                    <BlockButton
                        data={data}
                        converted={true}
                    />
                </div>
                {renderLeft()}
            </div>
            <CardItem
                notContained
                className='draft-button-bottom'
            >
                <Button
                    title='Submit'
                    disabled={messages.some(m => m.type === 'error')}
                    onClick={prepareData}
                />
            </CardItem>
        </>
    )
}