import { useRouter } from "next/router";
import { DEBUG, DOMAIN_BASE, NAME_BASE, URL_BASE } from "../../config";
import React from "react";
import Head from "next/head";
import { DESCRIPTION_DEFAULT } from "../templates";

export function SeoCommon({tags}) {

    const data = tags;

    if (!data) return null;

    return (
        <Head>
            {
                data.title
                    ? <title key='title'>{`${data.title} | ${NAME_BASE}`}</title>
                    : <title key='title'>{`${DEBUG ? 'Undefined title | ' : ''}${NAME_BASE}`}</title>
            }
            {data.description && <meta name="description" content={data.description} key='description'/>}
            {
                (!!DEBUG || !!data.noindex)
                    ? <meta name="robots" content="noindex, nofollow, noarchive, nocache, noimageindex" key='robots'/>
                    : <meta name="robots" content="" key='robots'/>
            }
        </Head>
    )
}

export function SeoOpenGraph({tags}) {

    const router = useRouter();
    const data = tags;

    return (
        <Head>
            <meta property='og:title' content={data?.title || DOMAIN_BASE} key='og:title'/>
            <meta
                property='og:description'
                content={data?.description || DESCRIPTION_DEFAULT}
                key='og:description'
            />
            <meta property='og:site_name' content={data?.site_name || NAME_BASE} key='og:site_name'/>
            <meta property='og:url' content={data?.url || URL_BASE + router.asPath} key='og:url'/>
            <meta property="og:image" content={data?.image || URL_BASE + "/img/trydirect-social.svg"} key='og:image'/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content={DOMAIN_BASE}/>
            <meta property="twitter:url" content={data?.url || URL_BASE + router.asPath}/>
            <meta name="twitter:title" content={data?.title || DOMAIN_BASE}/>
            <meta
                name="twitter:description"
                content={data?.description || DESCRIPTION_DEFAULT}
            />
            <meta name="twitter:image" content={data?.imageTwitter || data?.image || URL_BASE + "/img/trydirect-social.svg"}/>

            {data?.type && <meta property='og:type' content={data?.type} key='og:type'/>}
            {
                data?.custom && !!Object.keys(data?.custom).length &&
                Object.keys(data?.custom).map((prop, index) => {
                    return (
                        <meta
                            property={prop}
                            content={data?.custom[prop]}
                            key={prop + index + Math.random()}
                        />
                    )
                })
            }
        </Head>
    )
}

export function JSONLD({data, tagKey}) {
    // see details : https://schema.org/docs/schemas.html
    if (!data || !Object.keys(data).length) return null;

    return (
        <Head>
            <script
                type="application/ld+json" id={tagKey ? tagKey : 'content-jsonld'}
                key={tagKey ? tagKey : 'content-jsonld'}
            >
                {
                    JSON.stringify({
                        ...{"@context": "https://schema.org"},
                        ...data,
                    })
                }
            </script>
        </Head>
    )
}
