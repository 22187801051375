import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { convertEditorStateToHTML } from './exports';
import parse from 'html-react-parser';
import { dispatchNotification } from "../../../layouts/app/components/notificationSystem/notifications";
import { createFontBackgroundColorVariables, createFontColorVariables } from "../helpers/colors";

function DraftHTML({dark, className, content, styles, updateContent, nav, navDepth}) {

    function copyToClipboard(container) {

        const element = document.getElementById(container);

        window.getSelection().removeAllRanges();
        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().addRange(range);

        if (!navigator.clipboard) {  // old browsers
            document.execCommand("copy");
            dispatchNotification({
                message: 'Copied to clipboard', type: 'success',
            })
        } else {
            navigator.clipboard.writeText(element.innerText).then(
                () => {
                    dispatchNotification({
                        message: 'Copied to clipboard', type: 'success',
                    })
                })
                     .catch(
                         () => {
                             dispatchNotification({
                                 message: 'Error copying. Possible browser incompatibility.', type: 'error',
                             })
                         });
        }
    }

    useEffect(() => {
        const copyNodeList = document.querySelectorAll('[id^="draft-copy-code-"]');
        Array.from(copyNodeList).map(button => {
            const id = button.id.split('draft-copy-code-')[1];
            button.onclick = function () {
                copyToClipboard(`draft-code-${id}`)
            };
        })
    }, []);

    let __html = undefined;

    if (typeof content === 'string') __html = content;

    if (typeof content === 'object' && !!content?.blocks?.length) __html = convertEditorStateToHTML(content, {styles});

    if (!__html) return null;

    const renderNav = () => {

        const depth = navDepth || 6;
        const fullHeadingStringRegEXP = new RegExp(`<h[2-${depth}][^>]*>(.*?)<\\/h[2-${depth}]>`, "g");
        const headingsRaw = __html.match(fullHeadingStringRegEXP) || [];

        let headings = [];

        headingsRaw.map(h => headings.push([
            parseFloat(h.charAt(2)),
            h.match(/id="(.*?)"/gs)?.[0]?.split(`"`)[1],
            h.match(/data-text-content="(.*?)"/gs)?.[0]?.split(`"`)[1],
        ]));

        return (
            <ul
                style={{
                    backgroundColor: `var(--colorBackgroundBasicLight)`,
                    display: 'flex',
                    flexFlow: 'column',
                    padding: '15px 25px 15px 40px',
                    borderRadius: 'var(--borderRadiusContainerInnerEdge)',
                    width: 'fit-content',
                    marginBottom: '30px',
                }}
            >
                {
                    headings.map(heading => (
                        <li
                            style={{
                                marginLeft: `${(heading[0] - 2) * 15}px`,
                                marginBottom: '5px',
                            }}
                            key={heading[1]}
                        >
                            <a
                                style={{
                                    display: 'block',
                                    fontFamily: `var(--fontFamilySecondary)`,
                                    fontSize: `var(--fontSizeSecondary)`,
                                    fontWeight: `var(--fontWeightSecondaryBold)`,
                                }}
                                href={`#${heading[1]}`}

                            >
                                {heading[2]}
                            </a>
                        </li>
                    ))
                }
            </ul>
        )
    }

    return (
        <>
            {!!nav && renderNav()}
            <div
                className={`content-editor${!!className ? ' ' + className : ''}`}
                style={{
                    ...createFontColorVariables(dark),
                    ...createFontBackgroundColorVariables(dark),
                }}
            >
                {parse(__html)}
            </div>
        </>
    )
}

const mapStateToProps = state => ({dark: state.UI.theme.dark});

DraftHTML.propTypes = {
    className: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    styles: PropTypes.object,
    updateContent: PropTypes.bool,
}

export default connect(mapStateToProps)(DraftHTML)